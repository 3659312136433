import React from 'react';
import classes from './Page.module.css';
import Container from '../Components/Container';

import p2 from "../Assets/Images/p2.png";

const Olzhas = () => {
    return (
        <div className={classes.Page}>
            <Container>
                <div className={classes.person}>
                    <div className={classes.personImage}>
                        <img src={p2} alt="Кабдуев Олжас Шарипович" />
                    </div>
                    <div className={classes.personInfo}>
                        <h2>Кабдуев Олжас Шарипович</h2>
                        <p>родился 26 мая 1986 года в городе Караганда.</p>
                    </div>
                </div>
                <p className={classes.about}>
                    <span className={classes.hideDesktop}>родился 26 мая 1986 года в городе Караганда.</span>
                    Окончил с отличием Карагандинский Юридический Институт МВД РК им. Б.Бейсенова.
С 2007 по 2008 годы работал дознавателем в Северном и Южном ОП г.Павлодара.
С 2009 по 2015 годы занимал должности юрист и главный юрист в организациях среднего и крупного бизнеса, таких как: ТОО «Жан-Шеру», КХ «МАКО», ТОО «КАЗАХДОРСТРОЙ» (подразделение BI-Croup), АО «Станция Экибастузская «ГРЭС-2», РГУ «Департамент экологии по Павлодарской области».
За годы трудовой деятельности в качестве юриста, принято участие в более 100 гражданских дел, причем статистика «выигранных дел» составляет не менее 90%.
В 2015 году получил сертификат «Бизнес советник»
В этом же году принимает предложение от учредителей ТОО «Арлан-ПВЛ» и занимает должность коммерческого директора.
С 2017 по сегодняшний день года трудится на руководящих должностях (заместитель директора, директор) консалтинговой фирмы ТОО «IN-CLASSIC».
На данный момент ведет активную работу в области гражданского права, в том числе по отмене уведомлений по актам налоговых проверок и отмене решений других уполномоченных органов.
                </p>
            </Container>
        </div>
    );
};

export default Olzhas;