import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route, useLocation, useHistory } from "react-router-dom";
import Index from "./Pages/Index";
import "./App.css"
import Legal from "./Pages/Legal";
import Accounting from "./Pages/Accounting";
import Audit from "./Pages/Audit";
import Modal from "./Components/Modal";
import { MobileProvider } from "./Utils/Mobile";
import {DialogProvider} from "./Utils/Dialog";

import call from "./Assets/Icons/call.svg";
import send from "./Assets/Icons/send.svg"

import Erzhan from "./Pages/Erzhan";
import Olzhas from "./Pages/Olzhas";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import Form from "./Components/Form";

const App = () => {
  const [mobile, setMobile] = useState(window.innerWidth < 768);
  const [dialog, setDialog] = useState(false);
  useEffect(() => {
    const resize = () => {
      setMobile(window.innerWidth < 768)
    }
    window.addEventListener("resize", resize);
    return () => {
      window.removeEventListener("resize", resize);
    }
  }, [])
  return (
    <Router>
      <MobileProvider value={mobile}>
        <DialogProvider value={setDialog}>
          <Navbar />
          <MainSwitch />
          <Footer />
        </DialogProvider>
      </MobileProvider>
      {
        mobile ? <a href="tel:+77761370623" className="fab">
          <img src={call} height={24} alt="Call"/>
        </a> : <button onClick={() => setDialog(true)} className="fab">
          <img src={send} height={24} alt="Send"/>
        </button>
      }
      <Modal maxWidth={540} open={dialog} close={() => setDialog(false)}>
        <Form onDone={() => {
          setDialog(false)
          alert("Ваша заявка отправлена")
        }}/>
      </Modal>
    </Router>
  )
}

const MainSwitch = () => {
  const [open, setOpen] = useState(false)
  const location = useLocation();
  const history = useHistory();
  const background = location.state && location.state.background;
  useEffect(() => {
    setOpen(!!background)
  }, [background])
  return (
    <>
      <ScrollToTop>
        <Switch location={background || location}>
          <Route path="/" exact component={Index} />
          <Route path="/legal" exact component={Legal} />
          <Route path="/accounting" exact component={Accounting} />
          <Route path="/audit" exact component={Audit} />
          <Route path="/erzhan" exact component={Erzhan} />
          <Route path="/olzhas" exact component={Olzhas} />
        </Switch>
        <Modal open={open} close={() => {
          setOpen(false)
          setTimeout(history.goBack, 500)
        }}>
          <Route path="/legal" exact component={Legal} />
          <Route path="/accounting" exact component={Accounting} />
          <Route path="/audit" exact component={Audit} />
          <Route path="/erzhan" exact component={Erzhan} />
          <Route path="/olzhas" exact component={Olzhas} />
        </Modal>
      </ScrollToTop>
    </>
  )
}

const ScrollToTop = ({ children }) => {
  const location = useLocation();
  const [current, setCurrent] = useState(location.pathname);
  const [back, setBack] = useState(false)
  useEffect(() => {
    if(current === location.pathname){
      return
    }
    if(!back && !(location.state && location.state.background)){
      window.scrollTo(0, 0)
    }
    setBack(Boolean(location.state && location.state.background))
    setCurrent(location.pathname)
  }, [back, current, location]);
  return children;
};


export default App;