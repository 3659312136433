import React, { useState, useContext } from 'react';
import classes from './Navbar.module.css';
import logo from "../Assets/Logos/footer.png"
import Container from './Container';
import { Link } from 'react-router-dom';
import Button from './Button';
import clsx from 'clsx';
import DialogContext from '../Utils/Dialog';

const Navbar = () => {
    const [open, setOpen] = useState(false);
    const openDialog = useContext(DialogContext)
    return (
        <nav className={clsx(classes.Navbar, {
            [classes.open]: open
        })}>
            <Container className={classes.desktop}>
                <div className={classes.brand}>
                    <img src={logo} alt="In Classic logo" />
                </div>
                <div className={classes.links}>
                    <Link to="/">Главная</Link>
                    <Link to="/audit">Аудит</Link>
                    <Link to="/accounting">Бухалтерия</Link>
                    <Link to="/legal">Юридический отдел</Link>
                    <a className={classes.tel} href="tel:+77761370623">8-776-137-06-23</a>
                    <Button onClick={() => openDialog(true)}>Заказать услугу</Button>
                </div>
            </Container>
            <Container className={classes.mobile}>
                <div onClick={() => setOpen(true)} className={classes.burger}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="butt" strokeLinejoin="arcs"><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg>
                </div>
                <div className={classes.brand}>
                    <img src={logo} alt="In Classic logo" />
                </div>
                <div className={classes.links}>
                    <a className={classes.tel} href="tel:+77761370623">8-776-137-06-23</a>
                </div>
            </Container>
            <div className={classes.menuWrap}>
                <div onClick={() => setOpen(false)} className={classes.backdrop} />
                <div className={classes.menu}>
                    <div className={classes.brand}>
                        <img src={logo} alt="In Classic logo" />
                    </div>
                    <div onClick={() => setOpen(false)} className={classes.links}>
                        <Link to="/">Главная</Link>
                        <Link to="/audit">Аудит</Link>
                        <Link to="/accounting">Бухалтерия</Link>
                        <Link to="/legal">Юридический отдел</Link>
                    </div>
                    <a className={classes.tel} href="tel:+77761370623">
                        <svg width="24" height="24" viewBox="0 0 13 13" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.0953 1.90293C8.55649 -0.635091 4.4409 -0.634482 1.90288 1.90433C-0.635137 4.44313 -0.634527 8.5587 1.90428 11.0967C4.44309 13.6347 8.55865 13.6341 11.0967 11.0953C12.3156 9.87604 13.0002 8.22248 12.9998 6.49843C12.9994 4.77466 12.3144 3.12163 11.0953 1.90293ZM9.84379 9.05425C9.84352 9.05453 9.84324 9.05483 9.84293 9.05511V9.05295L9.51359 9.38011C9.08766 9.81142 8.46734 9.98888 7.87775 9.84811C7.28374 9.68912 6.71905 9.43602 6.20509 9.09846C5.7276 8.79329 5.28509 8.43655 4.88559 8.03461C4.51801 7.66972 4.18778 7.26906 3.89975 6.83861C3.58471 6.37544 3.33534 5.87088 3.15875 5.33927C2.95631 4.71476 3.12407 4.0295 3.59209 3.56911L3.97775 3.18346C4.08498 3.07575 4.25921 3.07537 4.36689 3.18259C4.36717 3.18287 4.36747 3.18315 4.36775 3.18346L5.58541 4.40111C5.69312 4.50834 5.6935 4.68257 5.58627 4.79025C5.58599 4.79053 5.58571 4.79081 5.58541 4.79111L4.87041 5.50611C4.66525 5.70904 4.63946 6.0315 4.80975 6.26446C5.06836 6.61937 5.35453 6.95333 5.66559 7.2633C6.0124 7.61161 6.38943 7.92845 6.79225 8.21014C7.02501 8.37249 7.34056 8.34511 7.54191 8.14514L8.23307 7.44314C8.34029 7.33543 8.51452 7.33505 8.6222 7.44228C8.62248 7.44255 8.62276 7.44283 8.62306 7.44314L9.84291 8.66514C9.95064 8.77234 9.95102 8.94654 9.84379 9.05425Z" fill="currentColor" />
                        </svg>
                        <span>8-776-137-06-23</span>
                    </a>
                    <Button onClick={() => {
                        setOpen(false);
                        openDialog(true)
                    }}>Заказать услугу</Button>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;