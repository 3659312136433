import React from 'react';
import classes from './Block.module.css';

const Block = ({title, children, icon}) => {
    return (
        <div className={classes.Block}>
            {icon ? <img className={classes.icon} src={icon} alt={title}/> : null}
            <h2>{title}</h2>
            {children}
        </div>
    );
};

export default Block;