import React from 'react';
import classes from './Page.module.css';
import Container from '../Components/Container';

import p1 from "../Assets/Images/p1.png";

const Erzhan = () => {
    return (
        <div className={classes.Page}>
            <Container>
                <div className={classes.person}>
                    <div className={classes.personImage}>
                        <img className={classes.fix} src={p1} alt="Науразбаев Ержан Рымбаевич" />
                    </div>
                    <div className={classes.personInfo}>
                        <h2>Науразбаев Ержан Рымбаевич</h2>
                        <p>родился 23 августа 1982 года в Павлодарской области.</p>
                    </div>
                </div>
                <p className={classes.about}>
                    <span className={classes.hideDesktop}>родился 23 августа 1982 года в Павлодарской области.</span>
                    В 2003 году окончил Павлодарский государственный университет им. С.Торайгырова по специальности финансы и кредит.
                Трудовую деятельность начал в 2005 году экономистом в крестьянском хозяйстве.
                Не долго проработав в плановом отделе, принял решение углубиться в изучение бухгалтерского учета и в последующие года занимал различные должности в сферах бухгалтерского учета и аудита, тем самым, прошел путь от рядового бухгалтера до финансового директора.
                На протяжении карьеры бухгалтера легко получал необходимые знания, как практические, так и теоретические, с последующим присвоением квалификаций: «1С-Профессионал», «Налоговый консультант», «Профессиональный бухгалтер», «Бизнес советник».
                С 2009 года по настоящее время активно принимает участие в проведении независимых аудиторских проверок. Под его руководством проведено более 20 аудиторских проверок и более 70 сопутствующих аудиту услуг в различных сферах деятельности, в том числе в государственных и полу государственных учреждениях, таких как: РГКП «Национальная академия образования им. Ы.Алтынсарина», АО «Центр по управлению персоналом государственной службы», АО «Международный аэропорт Семей», АО «Жасыл Даму», АО «Лечебно оздоровительный комплекс «Балхаш».
                В 2010-2011 годах участвовал в проекте «BALANS.KZ» на должности модератор сайта и консультант по бухгалтерскому учету и аудиту.
                В этот же период им было самостоятельно организовано и проведено более 20 выездных семинара из серии тем: «Нововведения в Налоговом кодексе», «Заполнение налоговых форм», «Комментарии по применению МСФО» и др.
                Был неоднократным участником круглых столов по вопросам налогового администрирования и других заседаний Палаты предпринимателей.
                Написал более 20 бизнес-планов и 70 статей по различным темам бухгалтерского и налогового учета, а также по внедрению и применению автоматизированных систем учета.
                В 2017 году участвовал в проекте «EXPO-2017» на должности ревизор в международной логистической компании DHL (аудит логистических услуг, предоставленных иностранным компаниям).
                В 2020 году участвовал в строительстве музея «Библиотека Первого Президента Республики Казахстан – Елбасы» в должности коммерческий директор ТОО «IN-CLASSICS» (музейное наполнение – изготовление скульптур).
                В данный момент является кандидатом в «Независимые аудиторы» и постоянным партнером (приглашенным специалистом) в таких аудиторских организациях как: ТОО «Учет и аудит -97», ТОО «АПБА-Аудит», ТОО «Аудит-Стандарт».
                </p>
            </Container>
        </div>
    );
};

export default Erzhan;