import React, { useState } from "react";
import classes from "./Form.module.css";
import Button from "./Button";
import InputMask from "react-input-mask";
import clsx from "clsx";
import axios from "axios";

const Form = ({ inModal, onDone = () => {} }) => {
  const [state, setState] = useState({
    name: "",
    tel: "",
    email: "",
  });
  const input = (field, mask, type = "text", placeholder) => {
    return mask ? (
      <InputMask
        mask={mask}
        type={type}
        value={state[field]}
        onChange={(e) =>
          setState({
            ...state,
            [field]: e.target.value,
          })
        }
        placeholder={placeholder}
      />
    ) : (
      <input
        type={type}
        value={state[field]}
        onChange={(e) =>
          setState({
            ...state,
            [field]: e.target.value,
          })
        }
        placeholder={placeholder}
      />
    );
  };
  const send = (e) => {
    e.preventDefault();
    if (!state.name || !state.tel || !state.email) {
      return alert("Заполните все поля");
    }
    const data = new FormData();
    data.append("name", state.name);
    data.append("email", state.email);
    data.append("tel", state.tel);
    axios({
      url: "/req.php",
      method: "POST",
      data: data,
    })
      .then((res) => {
        if (res.data && res.data.success) {
          setState({
            tel: "",
            name: "",
            email: "",
          });
          onDone();
        } else {
          alert("Ошибка при отправке");
        }
      })
      .catch((err) => {
        alert("Ошибка при отправке");
      });
  };
  return (
    <div
      className={clsx(classes.Form, {
        [classes.inModal]: inModal,
      })}
    >
      <form onSubmit={send}>
        <p>ОСТАВЬТЕ ЗАЯВКУ И МЫ ВАМ ПЕРЕЗВОНИМ</p>
        <div className={classes.field}>{input("name", "", "text", "Введите Имя")}</div>
        <div className={classes.field}>
          {input("email", "", "email", "Введите e-mail")}
        </div>
        <div className={classes.field}>
          {input("tel", "+7 (999) 999-99-99", "text", "+7 (***) *** ** **")}
        </div>
        
        <Button>
          Оставить заявку
        </Button>
      </form>
    </div>
  );
};

export default Form;
