import React from 'react';
import classes from './Page.module.css';
import Container from '../Components/Container';
import Block from '../Components/Block';

import i1 from "../Assets/Icons/i1.svg";
import i2 from "../Assets/Icons/i2.svg";
import i3 from "../Assets/Icons/i3.svg";

const fisrtBlock = `- сопровождение сделок купли-продажи;
- заключение и расторжение договоров;
- взыскание штрафных санкций;
- защита личных имущественных и неимущественных прав.
- участие в суде первой и апелляционной инстанций по делу об административном правонарушении.`.split("\n")

const secondBlock = `- составление и заключение договоров (брачный договор, договор о размере алиментов на содержание ребенка и др.);
- расторжение брака;
- взыскание алиментов на содержание ребенка и прекращение такого взыскания;
- раздел общей совместной собственности супругов;
- возмещение материального и морального вреда.`.split("\n")

const thirdBlock = `- выезд на место совершения административного правонарушения;
- составление и направление адвокатских запросов;
- подготовка необходимых документов (заявлений, пояснений, ходатайств, жалоб и др.);
- представление интересов Клиента в государственных органах;
- обжалование действий работников полиции, иных должностных лиц;
- участие в суде первой и апелляционной инстанций по делу об административном правонарушении.`.split("\n")

const Legal = () => {
    return (
        <div className={classes.Page}>
            <Container>
                <h1>Юридические услуги</h1>
                <Block icon={i1} title="Гражданское право">
                    {fisrtBlock.map((line, i) => (
                        <p key={i}>{line}</p>
                    ))}
                </Block>
                <Block icon={i2} title="Семейное право">
                    {secondBlock.map((line, i) => (
                        <p key={i}>{line}</p>
                    ))}
                </Block>
                <Block icon={i3} title="Административное право">
                    {thirdBlock.map((line, i) => (
                        <p key={i}>{line}</p>
                    ))}
                </Block>
            </Container>
        </div>
    );
};

export default Legal;