import React, { useState, useContext } from 'react';
import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import Container from "../Components/Container";
import Button from "../Components/Button";
import classes from './Slider.module.css';

import p1 from "../Assets/Images/p1.png";
import p2 from "../Assets/Images/p2.png";
import { Link, useLocation } from 'react-router-dom';
import MobileContext from '../Utils/Mobile';
import DialogContext from '../Utils/Dialog';

const Slider = () => {
    const [slide, setSlide] = useState(0);
    const location = useLocation();
    const mobile = useContext(MobileContext);
    const openDialog = useContext(DialogContext);
    return (
        <div className={classes.slider}>
            <Carousel keepDirectionWhenDragging infinite onChange={setSlide} value={slide}>
                <div className={classes.slide}>
                    <Container>
                        <div className={classes.slideImage}>
                            <img className={classes.fix} src={p1} alt="Науразбаев Ержан Рымбаевич" />
                        </div>
                        <div className={classes.slidePerson}>
                            <div className={classes.slideText}>
                                <div className={classes.label}>КОМАНДА INCLASSIC</div>
                                <h3>Науразбаев Ержан Рымбаевич</h3>
                                <p>Профессиональный бухгалтер, налоговый консультант, кандидат на квалификацию «Аудитор РК», автор многочисленных статей по дисциплинам «Финансовый учет и Налогообложение».<br />Участие в проектах «EXPO-2017», «Библиотека Первого Президента Республики Казахстан – Елбасы».<span className={classes.hideMobile}><br />Совместное проведение аудиторских процедур с аудиторскими организациями ТОО «Учет и аудит -97», ТОО «АПБА-Аудит», ТОО «Аудит-Стандарт».</span></p>
                            </div>
                            <div className={classes.slideButtons}>
                                <div className={classes.slideButtonGroup}>
                                    <Link to={{
                                        pathname: "/erzhan",
                                        state: mobile ? null : {
                                            background: location
                                        }
                                    }}>
                                        <Button>Подробнее</Button>
                                    </Link>
                                    <Button onClick={() => openDialog(true)}>Заказать услугу</Button>
                                </div>
                                <div className={classes.slideButtonArrows}>
                                    <button onClick={() => setSlide(slide - 1)} className={classes.arrowButton}><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="butt" strokeLinejoin="arcs"><path d="M19 12H6M12 5l-7 7 7 7" /></svg></button>
                                    <button onClick={() => setSlide(slide + 1)} className={classes.arrowButton}><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="butt" strokeLinejoin="arcs"><path d="M5 12h13M12 5l7 7-7 7" /></svg></button>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
                <div className={classes.slide}>
                    <Container>
                        <div className={classes.slideImage}>
                            <img src={p2} alt="Кабдуев Олжас Шарипович" />
                        </div>
                        <div className={classes.slidePerson}>
                            <div className={classes.slideText}>
                                <div className={classes.label}>КОМАНДА INCLASSIC</div>
                                <h3>Кабдуев Олжас Шарипович</h3>
                                <p>Диплом с отличием Карагандинский Юридический Институт МВД РК им. Б.Бейсенова.<br />Сертификат «Бизнес советник». Более 10 лет стажа в гражданском праве, в том числе по отмене уведомлений по актам налоговых проверок и отмене решений других уполномоченных органов.<br />Более 100 гражданских дел с 90% статистикой «выигранных дел».</p>

                            </div>
                            <div className={classes.slideButtons}>
                                <div className={classes.slideButtonGroup}>
                                    <Link to={{
                                        pathname: "/olzhas",
                                        state: mobile ? null : {
                                            background: location
                                        }
                                    }}>
                                        <Button>Подробнее</Button>
                                    </Link>
                                    <Button onClick={() => openDialog(true)}>Заказать услугу</Button>
                                </div>
                                <div className={classes.slideButtonArrows}>
                                    <button onClick={() => setSlide(slide - 1)} className={classes.arrowButton}><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="butt" strokeLinejoin="arcs"><path d="M19 12H6M12 5l-7 7 7 7" /></svg></button>
                                    <button onClick={() => setSlide(slide + 1)} className={classes.arrowButton}><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="butt" strokeLinejoin="arcs"><path d="M5 12h13M12 5l7 7-7 7" /></svg></button>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
            </Carousel>
        </div>
    );
};

export default Slider;