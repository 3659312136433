import React, { useContext } from 'react';
import { Link } from "react-router-dom"
import classes from './Footer.module.css';
import Container from './Container';
import DialogContext from "../Utils/Dialog"

import logo from "../Assets/Logos/footer.png"
import Button from './Button';

const Footer = () => {
    const openDialog = useContext(DialogContext)
    return (
        <footer className={classes.Footer}>
            <Container>
                <div className={classes.flex}>
                    <div className={classes.logo}>
                        <img src={logo} alt="In Classic logo" />
                    </div>
                    <div className={classes.menu}>
                        <div className={classes.links}>
                            <Link to="/">Главная</Link>
                            <Link to="/audit">Аудит</Link>
                            <Link to="/accounting">Бухалтерия</Link>
                            <Link to="/legal">Юридический отдел</Link>
                        </div>
                        <div className={classes.tels}>
                            <a href="tel:+77761370623">Бухгалтерия +7 (776) 137-06-23</a>
                            <a href="tel:+77476646386">Юридический отдел +7 (747) 664-63-86</a>
                            <a href="mailto:forsmazhory@bk.ru">E-mail: forsmazhory@bk.ru</a>
                        </div>
                    </div>
                    <Button onClick={() => openDialog(true)}>Заказать услугу</Button>
                </div>
                <a className={classes.copyright} href="https://creeon.kz">2020. Creeon Design. All right reserved</a>
            </Container>
        </footer>
    );
};

export default Footer;