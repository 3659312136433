import React from 'react';
import Container from "../Components/Container";
import Block from "../Components/Block";
import classes from './Page.module.css';

import i4 from "../Assets/Icons/i4.svg";
import i6 from "../Assets/Icons/i6.svg";

const fisrtBlock = `- построение системы учета;
- восстановление и сопровождение учета;
- консультации по вопросам налогообложения;
- подготовка и сдача всех видов отчётов`.split("\n");

const secondBlock = `- построение системы управленческого учёта;
- построение системы бюджетирования и планирования;
- анализ финансового состояния Клиента;
- проведение инвентаризаций и прочих учётных мероприятий.`.split("\n");

const Accounting = () => {
    return (
        <div className={classes.Page}>
             <Container>
                <h1>БУХГАЛТЕРСКИЕ УСЛУГИ</h1>
                <Block icon={i4} title="Бухгалтерский и налоговый учёт">
                    {fisrtBlock.map((line, i) => (
                        <p key={i}>{line}</p>
                    ))}
                </Block>
                <Block icon={i6} title="Антикризисное управление">
                    {secondBlock.map((line, i) => (
                        <p key={i}>{line}</p>
                    ))}
                </Block>
            </Container>
        </div>
    );
};

export default Accounting;