import React from 'react';
import classes from './Page.module.css';
import Container from '../Components/Container';

import i5 from "../Assets/Icons/i5.svg";
import Block from '../Components/Block';

const firstBlock = `- проведение обязательного аудита;
- проведение добровольного аудита;
- проведение налогового аудита;
- проведение согласованных процедур;`.split("\n")

const Audit = () => {
    return (
        <div className={classes.Page}>
            <Container>
                <h1>Аудиторские УСЛУГИ</h1>
                <Block icon={i5} title="">
                    {firstBlock.map((line,i) => (
                        <p key={i}>{line}</p>
                    ))}
                </Block>
            </Container>
        </div>
    );
};

export default Audit;