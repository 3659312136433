import React, {useContext} from 'react';
import Container from "../Components/Container";
import Button from "../Components/Button";
import card1 from "../Assets/Images/card1.jpg";
import card2 from "../Assets/Images/card2.jpg";
import card3 from "../Assets/Images/card3.jpg";
import classes from './Cards.module.css';
import { Link, useLocation } from 'react-router-dom';
import MobileContext from '../Utils/Mobile';
import DialogContext from '../Utils/Dialog';

const Cards = () => {
    const location = useLocation();
    const mobile = useContext(MobileContext);
    const openDialog = useContext(DialogContext);
    return (
        <div className={classes.services}>
                <Container>
                    <div className={classes.cards}>
                        <div style={{
                            backgroundImage: `url(${card1})`
                        }} className={classes.card}>
                            <div className={classes.cardContent}>
                                <h3>Аудиторские услуги</h3>
                                <p>В ходе аудита мы выполняем проверку параметров работы компании, производственных процессов и производимых компанией продуктов.</p>
                                <div className={classes.cardButton}>
                                    <Link to={{
                                        pathname: "/audit",
                                        state: mobile ? null : { background: location } 
                                    }}><Button>Подробнее</Button></Link>
                                    <Button onClick={() => openDialog(true)}>Заказать услугу</Button>
                                </div>
                            </div>
                        </div>
                        <div style={{
                            backgroundImage: `url(${card2})`
                        }} className={classes.card}>
                            <div className={classes.cardContent}>
                                <h3>Бухгалтерские услуги</h3>
                                <p>Мы предоставляем полный спектр услуг по ведению бухгалтерского учета Вашего бизнеса, формированию, а так же сдаче отчетности.</p>
                                <div className={classes.cardButton}>
                                    <Link to={{
                                        pathname: "/accounting",
                                        state: mobile ? null : { background: location } 
                                    }}><Button>Подробнее</Button></Link>
                                    <Button onClick={() => openDialog(true)}>Заказать услугу</Button>
                                </div>
                            </div>
                        </div>
                        <div style={{
                            backgroundImage: `url(${card3})`
                        }} className={classes.card}>
                            <div className={classes.cardContent}>
                                <h3>Юридические услуги</h3>
                                <p>Международный опыт и опыт на рынке Казахстана наших юристов постоянно обеспечивает положительный результат для наших клиентов.</p>
                                <div className={classes.cardButton}>
                                <Link to={{
                                        pathname: "/legal",
                                        state: mobile ? null : { background: location } 
                                    }}><Button>Подробнее</Button></Link>
                                    <Button onClick={() => openDialog(true)}>Заказать услугу</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
    );
};

export default Cards;