import React, { useState } from "react";
import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import Container from "../Components/Container";
//import Button from "../Components/Button";
import Slider from "../Components/Slider";
import Cards from "../Components/Cards";

import hero from "../Assets/Images/hero.png";

import about from '../Assets/Images/about.png'

import i1 from "../Assets/Icons/i1.svg";
import i2 from "../Assets/Icons/i2.svg";
import i3 from "../Assets/Icons/i3.svg";
import i4 from "../Assets/Icons/i4.svg";
import i5 from "../Assets/Icons/i5.svg";
import i6 from "../Assets/Icons/i6.svg";

import l1 from "../Assets/Logos/l1.png";
import l2 from "../Assets/Logos/l2.png";
import l3 from "../Assets/Logos/l3.png";
import l4 from "../Assets/Logos/l4.png";
import l5 from "../Assets/Logos/l5.png";
import l6 from "../Assets/Logos/l6.png";
import l7 from "../Assets/Logos/l7.png";

import doc1 from "../Assets/Images/doc1.jpg";
import doc2 from "../Assets/Images/doc2.jpg";
import doc3 from "../Assets/Images/doc3.jpg";

import classes from "./Index.module.css";
import { useEffect } from "react";
import clsx from "clsx";

const blocks = [
    {
        i: i1,
        t: "Гражданское право",
        l: `- сопровождение сделок купли-продажи;
        - заключение и расторжение договоров;
        - взыскание штрафных санкций;
        - защита личных имущественных и неимущественных прав.
        - участие в суде первой и апелляционной инстанций по делу об административном правонарушении.`
    },
    {
        i: i3,
        t: "Административное право",
        l: `- выезд на место совершения административного правонарушения;
        - составление и направление адвокатских запросов;
        - подготовка необходимых документов (заявлений, пояснений, ходатайств, жалоб и др.);
        - представление интересов Клиента в государственных органах;
        - обжалование действий работников полиции, иных должностных лиц;
        - участие в суде первой и апелляционной инстанций по делу об административном правонарушении.`
    },
    {
        i: i2,
        t: "Семейное право",
        l: `- составление и заключение договоров (брачный договор, договор о размере алиментов на содержание ребенка и др.);
        - расторжение брака;
        - взыскание алиментов на содержание ребенка и прекращение такого взыскания;
        - раздел общей совместной собственности супругов;
        - возмещение материального и морального вреда.`
    },
    {
        i: i4,
        t: "Бухгалтерский и налоговый учёт",
        l: `- построение системы учета;
        - восстановление и сопровождение учета;
        - консультации по вопросам налогообложения;
        - подготовка и сдача всех видов отчётов`
    },
    {
        i: i5,
        t: "Аудит",
        l: `- проведение обязательного аудита;
        - проведение добровольного аудита;
        - проведение налогового аудита;
        - проведение согласованных процедур;`
    },
    {
        i: i6,
        t: "Антикризисное управление",
        l: `- построение системы управленческого учёта;
        - построение системы бюджетирования и планирования;
        - анализ финансового состояния Клиента;
        - проведение инвентаризаций и прочих учётных мероприятий.`
    }
]

const Index = () => {
    const [slide, setSlide] = useState(0);
    const [docs, setDocs] = useState(0);
    const [scroll, setScroll] = useState(false);
    useEffect(() => {
        const setScrolled = () => {
            setScroll(window.scrollY > 20)
        }
        window.addEventListener("scroll", setScrolled)
        return () => {
            window.removeEventListener("scroll", setScrolled)
        }
    }, [])
    return (
        <div className={classes.Index}>
            <div className={classes.hero}>
                <Container>
                    <div className={classes.heroImage}>
                        <img src={hero} alt="IN CLASSIC" />
                        <h1>IN CLASSIC</h1>
                    </div>
                    <div className={clsx(classes.scroll, {
                        [classes.scrolled]: scroll
                    })}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"><path d="M6 9l6 6 6-6" /></svg>
                    </div>
                </Container>
            </div>
            <Cards />
            <div className={classes.aboutWrap}>
                <Container>
                    <div className={classes.about}>
                        <div className={classes.text}>
                            <h2>О компании</h2>
                            <p>Наша компания активно работает на рынке юридических и бухгалтерских услуг, мы накопили богатый опыт в области юриспруденции и обладаем обширными знаниями ведения бухгалтерии. Оказываем аудиторские услуги и предлагаем свою помощь в решении широкого спектра задач. Проконсультируем вас по всем юридическим вопросам, проведем бухгалтерский аудит и поможем в правильном ведении бухгалтерии. Мы добросовестно и открыто выстраиваем свою работу, на высоком профессиональном уровне ведем дела и в первую очередь заботимся об интересах наших клиентов. </p>
                        </div>
                        <div className={classes.image}>
                            <img src={about} alt="About" />
                        </div>
                    </div>
                </Container>
            </div>
            <Slider />
            <div className={classes.blocks}>
                <Container>
                    <h2>Мы Предоставляем Широкий Спектр Услуг Для Бизнеса</h2>
                    <div className={`${classes.blocksGrid} ${classes.hideMobile}`}>
                        {blocks.map((b) => (
                            <div key={b.t} className={classes.block}>
                                <img src={b.i} alt={b.t} />
                                <h3>{b.t}</h3>
                                {b.l.split("\n").map((line, i) => (
                                    <p key={i}>{line}</p>
                                ))}
                            </div>
                        ))}
                    </div>
                    <div className={`${classes.blocksSlider} ${classes.hideDesktop}`}>
                        <Carousel keepDirectionWhenDragging infinite value={slide} onChange={setSlide}>
                            {blocks.map((b) => (
                                <div key={b.t} className={classes.blockWrap}>
                                    <div className={classes.block}>
                                        <img src={b.i} alt={b.t} />
                                        <h3>{b.t}</h3>
                                        {b.l.split("\n").map((line, i) => (
                                            <p key={i}>{line}</p>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </Carousel>
                        <div className={classes.arrows}>
                            <button onClick={() => setSlide(slide - 1)}><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="butt" strokeLinejoin="arcs"><path d="M15 18l-6-6 6-6" /></svg></button>
                            <button onClick={() => setSlide(slide + 1)}><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="butt" strokeLinejoin="arcs"><path d="M9 18l6-6-6-6" /></svg></button>
                        </div>
                    </div>
                </Container>
            </div>
            <div className={classes.docs}>
                <Container>
                    <h2>Документы</h2>
                    <p>Наши специалисты профессионалы своего дела!</p>
                    <div className={`${classes.docsGrid} ${classes.hideMobile}`}>
                        {[doc1, doc2, doc3].map((el, i) => (
                            <div key={i} className={classes.doc}>
                                <a data-fancybox="docs" href={el}><img alt="Document" src={el} /></a>
                            </div>
                        ))}
                    </div>
                    <div className={`${classes.docsSlider} ${classes.hideDesktop}`}>
                        <Carousel keepDirectionWhenDragging value={docs} onChange={setDocs}>
                            {[doc1, doc2, doc3].map((el, i) => (
                                <div key={i} className={classes.doc}>
                                    <a data-fancybox="docs-mob" href={el}><img alt="Document" src={el} /></a>
                                </div>
                            ))}
                        </Carousel>
                        <div className={classes.arrows}>
                            <button onClick={() => setDocs(docs - 1 > 0 ? 2 : docs - 1)}><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="butt" strokeLinejoin="arcs"><path d="M15 18l-6-6 6-6" /></svg></button>
                            <button onClick={() => setDocs(docs + 1 > 2 ? 0 : docs + 1)}><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="butt" strokeLinejoin="arcs"><path d="M9 18l6-6-6-6" /></svg></button>
                        </div>
                    </div>
                </Container>
            </div>
            <div className={classes.partners}>
                <Container>
                    <div className={classes.label}>INCLASSIC</div>
                    <h2>Нам доверяют</h2>
                    <div className={classes.logos}>
                        {[l1, l2, l3, l4, l5, l6, l7].map((el, i) => (
                            <div style={{
                                gridArea: `l${i * 1 + 1}`
                            }} key={`logo-${i}`} className={classes.logo}>
                                <img src={el} alt="Partner" />
                            </div>
                        ))}
                    </div>
                </Container>
            </div>
        </div>
    )
}

export default Index;